import { Controller } from "@hotwired/stimulus"

export default class LastViewedAnchorController extends Controller {
  static values = {
    anchor: String
  }

  connect() {
    // var top = document.getElementById(this.anchorValue).offsetTop; 
    // window.scrollTo(0, top); 
    // var url = location.href;               //Save down the URL without hash.
    // location.href = "#"+this.anchorValue;                 //Go to the target element.
    // history.replaceState(null,null,url);
  }
}
