export default () => {
  const postContent = document.querySelector('#post_content');
  const uploadedAssets = document.querySelector('.edit_post__upload-assets');

  if(postContent){
    if(postContent.getAttribute("placeholder") == 'Say Something...'){
      postContent.style.minHeight = "150px";
    }
  }
  
  if(uploadedAssets){
    if(uploadedAssets.innerHTML == false){
      uploadedAssets.style.display = "none";
    }
  }
};
