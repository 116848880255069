import { Controller } from "@hotwired/stimulus"

export default class OnboardingUsernamesController extends Controller {
  static targets = ['hint', 'usernameInput'];

  connect() {
  }

  checkUsername() {
    const hintTarget = this.hintTarget;
    let input = document.querySelector('#user_username');

    input.value = input.value.replace(/[&.\?\/#\s:,]/ig, '');
    setTimeout(()=>{      
      if(!input.value) { return }
      fetch("/onboarding/username/check/" + input.value).then(function(response) {
        if (response.status != 204) {
          return response.text();
        }
      }).then(html => hintTarget.innerHTML = html)
    }, 500);
  }

  randomUsername() {
    const usernameInputTarget = this.usernameInputTarget;
    fetch("/onboarding/username/random/").then(function(response) {
      if (response.status != 204) {
        return response.text();
      }
    }).then(html => usernameInputTarget.value = html)
  }
}
