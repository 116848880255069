export default () => {
  // Username Functions
  let confirmBtn = document.querySelector('.edit_user > .btn--blue');
  let hint
  let hintContainer = document.querySelector('.hint')

  const statusChecker = () =>{
    setTimeout( function() {
      hint = document.querySelector('.hint > span');
      if(hint.style.color == "green"){
        confirmBtn.classList.remove('btn--deactivated');
      }else{
        confirmBtn.classList.add('btn--deactivated');
      }
    }, 200);
  };

  if(confirmBtn){
    let hintObserver = new MutationObserver(function(mutations) {         
      statusChecker();    
    });    
    
    hintObserver.observe(hintContainer, { 
      attributes: false, 
      childList: true, 
      characterData: false, 
      subtree: true 
    });
  };

  // let input = document.querySelector('#user_username');

  // input.addEventListener('keyup', ()=>{
  //   !(/^[A-Za-zÑñ-\s]*$/i).test(input.value) ? input.value = input.value.replace(/[^A-Za-zÑñ-\s]/ig, '') : null;
  // })


  // Tags Functions
  let continueBtn = document.querySelector('.onboarding__tags .btn--blue');  
  let tagsContainer
  let tagsTurbo = document.querySelector('#followed_tags');

  const tagsChecker = () => {
    tagsContainer = tagsTurbo.querySelector('.current-tags');
    if(tagsContainer.childElementCount > 1){
      continueBtn.classList.remove('btn--deactivated');
    }else{
      continueBtn.classList.add('btn--deactivated');
    }
  }
  
  if(tagsTurbo){    
    tagsChecker();    
    tagsTurbo.addEventListener('DOMNodeInserted', tagsChecker);
  }


};