export default () => {
	const add = [...document.querySelectorAll('.header-nav-list__anchor-add')];
	const nav = document.getElementById('header-add');

	if(add) {
		add.forEach((icon) => {
			icon.addEventListener('click', () => {
				nav.classList.toggle('open-add-nav');
			});
		});
	}
};