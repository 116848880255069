export default () => {
  const disputeType = document.querySelectorAll('.purchase_dispute_dispute_type input[type=radio]')
  const notDelivered = document.getElementById('purchase_dispute_dispute_type_item_not_delivered');
  const trackingNumber = document.querySelector('.purchase_dispute_tracking_number_present');
  const waited = document.querySelector('.purchase_dispute_over_14_days');

  if(disputeType) {
    disputeType.forEach((radio) => {
      radio.addEventListener('change', () => {
        if(notDelivered.checked == true) {
          trackingNumber.classList.add('dispute-field-expand')
          waited.classList.add('dispute-field-expand')
        } else {
          trackingNumber.classList.remove('dispute-field-expand')
          waited.classList.remove('dispute-field-expand')
        }
      });
    });
  }  
  
};