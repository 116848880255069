import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"


export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    useIntersection(this)
  }

  appear(entry){
    fetch(this.urlValue)
  }
}
