export default () => {
  const homeIconCont = document.querySelector('.home_icons')
  const homeIcon1 = document.querySelector('.home_icons-1')
  const homeIcon2 = document.querySelector('.home_icons-2')

  if(homeIconCont) {
    homeIconCont.addEventListener('mouseover', () => {
      homeIcon1.style.opacity = '0';
      homeIcon2.style.opacity = '1';
    });

    homeIconCont.addEventListener('mouseout', () => {
      homeIcon1.style.opacity = '1';
      homeIcon2.style.opacity = '0';
    });
  }

  const searchIconCont = document.querySelector('.search_icons')
  const searchIcon1 = document.querySelector('.search_icons-1')
  const searchIcon2 = document.querySelector('.search_icons-2')

  if(searchIconCont != null){
  
    searchIconCont.addEventListener('mouseover', () => {
      searchIcon1.style.opacity = '0';
      searchIcon2.style.opacity = '1';
    });

    searchIconCont.addEventListener('mouseout', () => {
      searchIcon1.style.opacity = '1';
      searchIcon2.style.opacity = '0';
    });
  }

  
  const postIconCont = document.querySelector('.post_icons')
  const postIcon1 = document.querySelector('.post_icons-1')
  const postIcon2 = document.querySelector('.post_icons-2')

  if(postIconCont){
    postIconCont.addEventListener('mouseover', () => {
      postIcon1.style.opacity = '0';
      postIcon2.style.opacity = '1';
    });

    postIconCont.addEventListener('mouseout', () => {
      postIcon1.style.opacity = '1';
      postIcon2.style.opacity = '0';
    });
  }

  
  const personIconCont = document.querySelector('.person_icons')
  const personIcon1 = document.querySelector('.person_icons-1')
  const personIcon2 = document.querySelector('.person_icons-2')

  if(personIconCont != null){
  
    personIconCont.addEventListener('mouseover', () => {
      personIcon1.style.opacity = '0';
      personIcon2.style.opacity = '1';
    });

    personIconCont.addEventListener('mouseout', () => {
      personIcon1.style.opacity = '1';
      personIcon2.style.opacity = '0';
    });
  }

  const inboxIconCont = document.querySelector('.inbox_icons')
  const inboxIcon1 = document.querySelector('.inbox_icons-1')
  const inboxIcon2 = document.querySelector('.inbox_icons-2')

  if(inboxIconCont != null){
  
    inboxIconCont.addEventListener('mouseover', () => {
      inboxIcon1.style.opacity = '0';
      inboxIcon2.style.opacity = '1';
    });

    inboxIconCont.addEventListener('mouseout', () => {
      inboxIcon1.style.opacity = '1';
      inboxIcon2.style.opacity = '0';
    });
  }

};
