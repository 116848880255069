export default () => {
  const firstStep = document.querySelector('.purchases__delivery-form')
  const orderSummary = document.querySelector('.purchases__order-summary')
  const orderSummaryBtn = document.querySelector('.purchases__confirm-summary > .purchases__order-summary-header');
  const osBtnText = document.querySelector('.purchases__order-summary-header > h3');

  if(firstStep){
    orderSummary.classList.add('order-summary-open');
  }

  if(orderSummaryBtn){
    orderSummaryBtn.addEventListener('click', () => {
      orderSummary.classList.toggle('order-summary-open');
      if (osBtnText.innerHTML == " Show order summary"){
        osBtnText.innerHTML = " Hide order summary"
      }else{
        osBtnText.innerHTML = " Show order summary"
      };
    })
  }
};