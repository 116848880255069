import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editbtn","container"];  

  toggle(event) {
    event.preventDefault();
    this.editbtnTarget.closest('.post').querySelector('.post_edit_box__links').classList.toggle('post_edit_box__open')
  }
}

