export default () => {

  let editBtn = document.getElementsByClassName('post-more__icon');
  let editDropdown = document.getElementsByClassName('post_edit_box__links');  
  const postsFeed = document.querySelector('.posts');  

  if(editBtn && postsFeed) { 
    document.addEventListener("click", (e)=>{
      for (let i = 0; i < editDropdown.length; i++) {
        let clickInside1 = editBtn[i].contains(e.target);
        let clickInside2 = editDropdown[i].contains(e.target);      
        if(!clickInside1 && !clickInside2){        
          if(editDropdown[i].classList.contains('post_edit_box__open')){
            editDropdown[i].classList.remove('post_edit_box__open')
          }
        }
      }
    })
  }

  
  
};