export default () => { 

  const URL = window.location.pathname.split('/');
  
  if(URL[3] == 'reply'){
  
    const element = document.getElementById('new_post');
    const offset = 45;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });

  };  

};