import '@hotwired/turbo-rails';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'trix';
import '@rails/actiontext'
// import '../channels';
import '../controllers';

export const getTurbo = async () => {
  if (!window.Turbo) {
    const Turbo = require('@hotwired/turbo')
    await Turbo.start()
    window.Turbo = Turbo
  }
  return window.Turbo
}

getTurbo();

ActiveStorage.start();

// prevent Trix file drag & drop
document.addEventListener('trix-file-accept', (e) => {
  e.preventDefault();
});
