export default () => {

  let prevScrollpos = window.pageYOffset;
  let subNav = document.querySelector('.sub-navigation');
  let subNavTags = document.querySelector('.tags_top');
  const desktop = window.innerWidth > 1200;
  const mobile = window.innerWidth < 1200;

  
  if(subNav) {
    window.onscroll = () => {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos && desktop) {
        subNav.style.top = "60px";
      } else if(prevScrollpos > currentScrollPos && mobile){
        subNav.style.top = "0px";
      } else {
        subNav.style.top = "-60px";
      };     
      
      prevScrollpos = currentScrollPos;
    }
  }
  
  if(subNavTags) {
    window.onscroll = () => {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos && desktop) {
        subNavTags.style.top = "60px";
        subNavTags.style.boxShadow = "0px 1px 5px -3px rgba(0,0,0,30%)";
      } else if(prevScrollpos > currentScrollPos && mobile){
        subNavTags.style.top = "45px";
        subNavTags.style.boxShadow = "0px 1px 5px -3px rgba(0,0,0,30%)";
      } else {
        subNavTags.style.top = "-10px";
        subNavTags.style.boxShadow = "0px 1px 5px -3px rgba(0,0,0,0%)";
      };
      
      prevScrollpos = currentScrollPos;
    }
  }

};