export default () => {
  const searchForm = document.querySelector('.search-form');
  const userBanner = document.querySelector('.user-banner');
  const messagesHeader = document.querySelector('.messages-heading');
  const editPostHeader = document.querySelector('.edit-posts__mobile-header');
  const purchasePages = document.querySelector('.purchases');
  const onboardingPage = document.querySelector('.onboarding');
  const userLogin = document.querySelector('.user-login')
  const landingPage = document.querySelector('.landing');
  const loginPage = document.querySelector('.user-login');
  const messagesConvoPage = document.querySelector('.messages__convo');
  const body = document.body;
  const termsPage = document.querySelector('.terms');
  const followPages = document.querySelector('.users__follow-container');
  const purchaseSuccess = document.querySelector('.purchases__end-message');

  if(searchForm && window.innerWidth < 1200) {
    body.classList.add('remove-header')
  }

  if(userBanner && window.innerWidth < 1200) {
    body.classList.add('remove-header')
  }

  // if(messagesHeader && window.innerWidth < 1200) {
  //   body.classList.add('remove-header')
  // }

  if(editPostHeader && window.innerWidth < 1200) {
    body.classList.add('remove-header')
  }

  if(purchasePages && window.innerWidth < 1200) {
    body.classList.add('remove-header')
  }

  if(onboardingPage && window.innerWidth < 1200) {
    body.classList.add('remove-header')
    document.querySelector('.mobile-navigation').style.display = 'none';
  }

  if(userLogin && window.innerWidth < 1200) {
    body.classList.add('remove-header')
    document.querySelector('.mobile-navigation').style.display = 'none';
  }
  
  if(messagesConvoPage && window.innerWidth < 1200) {
    body.classList.add('remove-header')
  }

  if(loginPage && window.innerWidth < 1200) {
    document.getElementById("mobile-navigation").style.display = "none";
  }

  if(landingPage && window.innerWidth < 1200) {
    document.getElementById("mobile-navigation").style.display = "none";
  }

  if(termsPage && window.innerWidth < 1200) {
    body.classList.add('remove-header')
    document.getElementById("mobile-navigation").style.display = "none";
  }
  
  if(followPages && window.innerWidth < 1200) {
    body.classList.add('remove-header')
    document.getElementById("mobile-navigation").style.display = "none";
  }

  if(purchaseSuccess && window.innerWidth < 1200) {
    document.getElementById("mobile-navigation").style.display = "none";
  }
};
