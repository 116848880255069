export default () => {
  const stripeBg = document.querySelector('.stripe-modal');
  
  if(stripeBg){
    stripeBg.addEventListener('click', (e) => {
      if(e.target.className == 'stripe-modal'){
        stripeBg.style.display = 'none';
      }
    })
  }
};