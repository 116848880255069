export default () => { 
  const addImgBtn = document.querySelector('.add-img-btn');
  const addImgBtnCont = document.querySelector('.dropzone-msg.dz-message.needsclick');
  let imgPreview
  const dropZoneArea = document.querySelector('.dropzone-default');
  
  if(addImgBtn){  
    const addImgBtnMover = () => {
      imgPreview = document.querySelectorAll('.dz-preview')    
      if(imgPreview.length > 0){
        addImgBtn.classList.add('add-img-btn-move');
        addImgBtnCont.classList.add('img-btn-move');
      }
      else{
        addImgBtn.classList.remove('add-img-btn-move');
        addImgBtnCont.classList.remove('img-btn-move');
      }  
    }  
    window.setInterval(addImgBtnMover, 1000);
  }
 
  
};

