import { Controller } from "@hotwired/stimulus"

export const getTurbo = async () => {
  if (!window.Turbo) {
    const Turbo = require('@hotwired/turbo')
    await Turbo.start()
    window.Turbo = Turbo
  }
  return window.Turbo
}

getTurbo();

export default class TagSearchController extends Controller {
  static targets = ['form', 'tagList', 'tagSearchBox'];

  connect() {
  }

  tagSearch(event){
    var content = event.srcElement.value;
    let formData = new FormData()
    formData.append("tag_search[searchterm]", content);
    fetch("/ttags/tag_searches", {
          body: formData,
          method: 'POST',
          credentials: "include",
          Accept: 'text/vnd.turbo-stream.html',
          headers: {
            "X-CSRF-Token": this.getMetaValue("csrf-token")
          }
    }).then(function(response) {
      if (response.status != 204) {
        return response.text();
      }
    }).then(html => Turbo.renderStreamMessage(html))
  }
}
