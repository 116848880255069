export default () => {
  const elementEditor = document.querySelector('#post_content');
  let processedDocumentText;
  let maxChar
  let textFieldLabel = document.querySelector('label[for="post_content"]');
  const btn = document.querySelector('#new-post-btn');
  const counter = document.querySelector('.post-char-counter');

  if(textFieldLabel){
    maxChar = 1500;
  } else {
    maxChar = 1000;
  }

  if(elementEditor){

    const setCount = (length) => {
      let adjustedLength = length - 1;
      if (adjustedLength > 0) {
        counter.innerText = adjustedLength + '/' + maxChar;
      }
      else {
        counter.innerText = maxChar;
      }
    }

    document.addEventListener('trix-change', () => {    
      let newDocumentText = elementEditor.editor.getDocument().toString();    
      if (!processedDocumentText) {
        processedDocumentText = newDocumentText;
      }
      var length = newDocumentText.length;
      setCount(length);

      if (processedDocumentText && processedDocumentText !== newDocumentText) {
        processedDocumentText = newDocumentText;

        if (length > maxChar) {
          var currentSelectedRange = elementEditor.editor.getSelectedRange();

          //deselect previous
          elementEditor.editor.setSelectedRange([0, length + 1]);
          elementEditor.editor.deactivateAttribute('strike');

          elementEditor.editor.setSelectedRange([maxChar, length]);
          elementEditor.editor.activateAttribute("strike");

          //restore state
          elementEditor.editor.setSelectedRange(currentSelectedRange);

          // btn disable
          btn.classList.remove('new-post-btn-active')
        }
      }
      
    })
  };
  
};
