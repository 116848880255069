export default () => {
  const user = document.getElementById('user');
  const dropdown = document.querySelector('.user-dropdown');

  if(user) {
    user.addEventListener('click', () => {
      dropdown.classList.toggle('open-dropdown');
    });
  }
};
