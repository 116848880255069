export default () => {

  let inviteBtn = document.querySelector('.invite-btn')
  const flash = document.querySelector('#flash');
  // let shareText = document.querySelector('.onboarding__share-text');

  if(inviteBtn){
    inviteBtn.addEventListener('click', ()=>{       
      if(navigator.share){
        navigator.share({
          title:'Trezured',
          text: "Hey! Come join me at Trezured",
          url: 'https://trezured.com',
        })
        .then(() => {flash.innerHTML = '<div class="flash notice">Thanks for the share!</div>'});
      }else{
        navigator.clipboard.writeText('https://trezured.com').then(() => {
          flash.innerHTML = '<div class="flash notice">Copied to clipboard</div>'
        });
      }
    });
  }

};