import { Controller } from "@hotwired/stimulus"
import Siema from 'siema';


// Connects to data-controller="post-img-slider"
export default class extends Controller {

  connect() {
    const prev = this.element.closest('.post-image').querySelector('.img-slide-left');
    const next = this.element.closest('.post-image').querySelector('.img-slide-right');
    let perView = Number(this.element.dataset.slidesPerView);
    const mobileOnly = this.element.dataset.mobileOnly;

    if(mobileOnly == "true" && screen.width <= 500){
      console.log('mobile only')
      const siema = new Siema({
        selector: this.element,
        duration: 200,
        easing: 'ease-out',
        perPage: perView,
        startIndex: 0,
        draggable: true,
        multipleDrag: false,
        threshold: 20,
        loop: true,
        rtl: false,
      });
    } else if (mobileOnly == 'false'){
      const siema = new Siema({
        selector: this.element,
        duration: 200,
        easing: 'ease-out',
        perPage: perView,
        startIndex: 0,
        draggable: true,
        multipleDrag: false,
        threshold: 20,
        loop: true,
        rtl: false,
      });
      if(prev && next){
        prev.addEventListener('click', () => siema.prev());
        next.addEventListener('click', () => siema.next());
      }
    } else {
      console.log('Please add a data-mobile-only attribute');
    }

  }
}
