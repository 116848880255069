import { Controller } from "@hotwired/stimulus"

export default class UserProfileEditorController extends Controller {
  static targets = ['charCount', 'submitButton'];

  connect() {
    document.addEventListener("trix-change", this.update.bind(this));
  }

  update() {
    try {
      var editor = document.getElementById('user_biography').editor;
      var content = editor.getDocument().toString();
      var charCount = this.charCountTarget;
      var contentLength = content.length -1;
      charCount.innerHTML = contentLength;
      var biocharCount = document.querySelector('.bio-edit-charCount')

      if(contentLength > 160) {
        this.submitButtonTarget.style.opacity = '0.5';
        this.submitButtonTarget.style.pointerEvents = 'none';
        biocharCount.style.color = 'red';
      } else {
        this.submitButtonTarget.style.opacity = '1';
        this.submitButtonTarget.style.pointerEvents = 'auto';
        biocharCount.style.color = 'black';
      }
    } catch(_error) {
      return;
    }
  }
}
