import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["videocontainer", "video"];

  playpause(event) {
    event.preventDefault();    
    if (this.videoTarget.paused == false) {
      this.videoTarget.pause();
      this.videoTarget.closest('.slide').querySelector('.video-playicon').classList.add('video-icon-hide')
      this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.remove('video-icon-hide')
      this.videoTarget.closest('.slide').querySelector('.video-controls-container').classList.remove('video-icon-hide')
    } else {
      this.videoTarget.play();
      this.videoTarget.closest('.slide').querySelector('.video-playicon').classList.add('video-icon-hide')
      this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.remove('video-icon-hide')
      this.videoTarget.closest('.slide').querySelector('.video-controls-container').classList.add('video-icon-hide')
    }
    this.pauseHide();
  }

  imgControlHide(){
    this.videocontainerTarget.querySelectorAll('.slide > img').forEach(el =>{
      el.closest('.slide').querySelector('.video-controls-container').style.display = 'none';
    })
  }

  pauseHide(){
    if(this.videoTarget){

      this.videoTarget.addEventListener('mouseenter',()=>{
        this.videoTarget.closest('.slide').querySelector('.video-controls-container').classList.remove('video-icon-hide')
        if(this.videoTarget.paused == false){
          this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.remove('video-icon-hide')
        }
      })

      this.videoTarget.addEventListener('mouseleave',()=>{
        if(this.videoTarget.paused){
          this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.remove('video-icon-hide')
          this.videoTarget.closest('.slide').querySelector('.video-controls-container').classList.remove('video-icon-hide')
        }else{      
          this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.add('video-icon-hide')
          this.videoTarget.closest('.slide').querySelector('.video-controls-container').classList.add('video-icon-hide')
        }
      })

      this.videoTarget.closest('.slide').querySelector('.video-controls-container').addEventListener('mouseenter',()=>{
        if(this.videoTarget.paused == false){
          this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.remove('video-icon-hide')
        }else{
          this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.add('video-icon-hide')
          this.videoTarget.closest('.slide').querySelector('.video-playicon').classList.remove('video-icon-hide')
        }
      })

      this.videoTarget.closest('.slide').querySelector('.video-controls-container').addEventListener('mouseleave',()=>{
        if(this.videoTarget.paused){
          this.videoTarget.closest('.slide').querySelector('.video-pauseicon').classList.remove('video-icon-hide')
          this.videoTarget.closest('.slide').querySelector('.video-playicon').classList.add('video-icon-hide')
        }
      })

    }
  }


  connect(){
    this.imgControlHide();
  }



}
