import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button","container"];

  toggle(event) {
    this.containerTarget.classList.toggle("posts-expanded");
    event.preventDefault();
    if(this.containerTarget.classList.contains("posts-expanded")){
      this.buttonTarget.textContent = 'See Less'
    }else {
      this.buttonTarget.textContent = 'See More'
    }
  }
}