export default () => {
  const header = document.getElementById('header');
  const menu = document.getElementById('mobile-menu');
  const hamburger = document.getElementById('hamburger');
  const filter = document.querySelector('.feed-filter');

  if (!menu || hamburger.length === 0) return;

  hamburger.addEventListener('click', () => {
    menu.classList.toggle('open-menu');
    hamburger.classList.toggle('toggle-hamburger');
    header.classList.toggle('header--black');
    filter.classList.toggle('quickhide');
  });

};
