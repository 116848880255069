import FontFaceObserver from 'fontfaceobserver';

const Rubik = new FontFaceObserver('Rubik');
Rubik.load()
  .then(() => {
    document.body.classList.add('Rubik-loaded');
  })
  .catch((err) => {
    document.body.classList.add('Rubik-failed');
  });
