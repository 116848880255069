export default () => {
  const addressDropdown = document.querySelector('#purchase_delivery_address_id');
  const addressForm = document.querySelector('.purchases__delivery-form--fields');

  if(addressDropdown) {
    addressDropdown.addEventListener('change', () => {
      if(addressDropdown.value.length == 1) {
        addressForm.classList.add("delivery-form-closed")
      } else {
        addressForm.classList.remove("delivery-form-closed")
      }
    });
  }  
  
};