export default () => {
 
  let price = document.querySelector("#post_price");

  if(price){
    // if(price.value < 0.5){
    //   price.value = 0.50; 
    // }
    price.addEventListener('change', () => {
      if(price.value < '0.5'){
        price.value = '0.00'; 
      }
    })
  }

};