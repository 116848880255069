import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sharebtn"];  

  share(event) {
    event.preventDefault();
    const flash = document.querySelector('#flash');
    let shareText = document.querySelector('.onboarding__share-text');
    
    if(shareText){
      if(navigator.share){
        navigator.share({
          title:'Trezured',
          text: shareText.innerHTML,
          url: 'https://trezured.com',
        })
        .then(() => {flash.innerHTML = '<div class="flash notice">Thanks for the share!</div>'});
      }else{
        navigator.clipboard.writeText('https://trezured.com').then(() => {
          flash.innerHTML = '<div class="flash notice">Copied to clipboard</div>'
        });
      }
    }

  }

}