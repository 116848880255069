export default () => {
  let postTextField = document.getElementById('direct_message_content');
  let postBtn = document.querySelector('#new-post-btn svg');
  let dmConvo = document.querySelector('.messages__convo');
  let dmForm = document.querySelector('.new_direct_message');
  let dmPhotoBtn = document.querySelector('.dm-images-icon');
  let dzContainer = document.querySelector('.dz-clickable');
  let formHeight

  if(postTextField){
    dmPhotoBtn.addEventListener('click', () => {
      dzContainer.click();
    })
  };


  if(dmConvo){
    dmForm.addEventListener('DOMNodeInserted', ()=> {
      formHeight = dmForm.offsetHeight + 80;
      dmConvo.style.paddingBottom = formHeight+'px';
    })
    
    dmForm.addEventListener('DOMNodeRemoved', ()=> {
      formHeight = dmForm.offsetHeight + 80;
      dmConvo.style.paddingBottom = formHeight+'px';
    })

    window.scrollTo(0, document.body.scrollHeight);
  }
};
