import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sharebtn", "flamemodal"];  

  share(event) {
    event.preventDefault();
    
    const flash = document.querySelector('#flash');
    let postContentFeed = this.sharebtnTarget.closest('.post-bottom').querySelector('.post-bottom__link');
    let postLink = this.sharebtnTarget.closest('.post').querySelector('.post-time__link').href;
    
    if(postContentFeed){
      if(navigator.share){
        navigator.share({
          title:'Trezured',
          text: postContentFeed.textContent,
          url: postLink,
        })
        .then(() => {flash.innerHTML = '<div class="flash notice">Thanks for the share!</div>'})
        .catch((error) => {console.log('Error share', error)});
      }else{
        navigator.clipboard.writeText(postLink).then(() => {
          flash.innerHTML = '<div class="flash notice">Copied to clipboard</div>'
        });
      }
    }

    let postContentFocus
    let postFocus = this.sharebtnTarget.closest('.post-focus');

    if(postFocus){
      postContentFocus = this.sharebtnTarget.closest('.post-bottom').querySelector('.post-bottom__description').querySelector('.trix-content');
      if(navigator.share){
        navigator.share({
          title:'Trezured',
          text: postContentFocus.textContent,
          url: postLink,
        })
        .then(() => {flash.innerHTML = '<div class="flash notice">Thanks for the share!</div>'})
        .catch((error) => {console.log('Error share', error)});
      }else{
        navigator.clipboard.writeText(postLink).then(() => {
          flash.innerHTML = '<div class="flash notice">Copied to clipboard</div>'
        });
      }
    }

  }

  flamepopup(event) {
    event.preventDefault();
    this.flamemodalTarget.classList.toggle("flame-modal__open")
  }

  connect(){
    let flameModals =  document.getElementsByClassName("flame-modal")
    let flameBtns =  document.getElementsByClassName("post-links__icon--flame")
    let flameBigBtns =  document.getElementsByClassName("trezured-blaze-btn")
    document.addEventListener("click", (e)=>{
      for (let i = 0; i < flameModals.length; i++) {
        let clickInside1 = flameModals[i].contains(e.target);  
        let clickInside2 = flameBtns[i].contains(e.target);  
        let clickInside3 = flameBigBtns[i].contains(e.target);  
        if(!clickInside1 && !clickInside2 && !clickInside3){        
          if(flameModals[i].classList.contains('flame-modal__open')){
            flameModals[i].classList.remove('flame-modal__open')
          }
        }
      }
    })
  }

}