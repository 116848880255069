import { Controller } from "@hotwired/stimulus"

export default class UserProfileBiographyController extends Controller {
  static targets = ['expandedBiography', 'truncatedBiography'];

  connect() {
    this.expandedBiographyTarget.style.display = 'none';
  }

  expand(event) {
    this.expandedBiographyTarget.style.display = 'block';
    this.truncatedBiographyTarget.style.display = 'none';
    event.preventDefault();
  }
}
